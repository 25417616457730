<template>
  <v-card class="w-full">
    <v-toolbar style="margin-bottom: 5px;" elevation="1" flat>
      <v-toolbar-title>
        <span class="headline">Novo Local</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon large @click="closeDialog">mdi-close</v-icon>
    </v-toolbar>
    <v-col cols="12" md="12">
      <div class="d-flex flex-wrap justify-space-between">
        <label v-if="!capaUrl" for="logo" style="cursor: pointer; width: 100%"
          class="caption d-flex justify-center flex-wrap border mr-4 px-2" title="Add logo cliente">
          <v-icon x-large color="primary" for="logo" class="logo-container mb-2">mdi-cloud-upload</v-icon>
          <span class="mb-2 w-full text-center"> Foto Principal </span>
          <input id="logo" style="display: none" type="file" @change="handleUpload('capa', $event)" />
        </label>
        <v-img v-else max-height="250" style="width: 15%"
          class="caption d-flex justify-center flex-wrap border mr-4 px-2" :src="capaUrl">
        </v-img>
        <div v-if="capaUrl" style="cursor: pointer;" class="w-full d-flex justify-end pr-2 mt-2 hover-red"
          @click="capaUrl = ''">
          <v-icon> mdi-delete </v-icon>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <v-switch v-model="mostraambiente" inset label="Mostrar Design de Ambientes"></v-switch>

      <v-text-field v-model="descricao" label="Descricao" />

      <v-select v-model="tiposCadeiras" label="Tipos" :items="tipos" chips />

      <v-text-field disabled v-model="formatedData" label="Data" />

      <v-select v-model="cliente" label="Empresa Vinculada" :items="estabelecimentos" chips multiple />

      <v-expansion-panels focusable multiple v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="subtitle-2">Endereço</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row no-gutters>
              <v-col cols="12" md="6" sm="12">
                <v-text-field v-mask="'#####-###'" v-model="cep" label="CEP"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12" align-self="center">
                <v-btn class="ml-2" color="primary" outlined :loading="loadingBtn" hide-datais
                  @click="consultarCEP">Consultar CEP</v-btn>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field v-model="logradouro" label="Endereço (Rua,Av.,etc)*" required></v-text-field>
              </v-col>

              <v-col cols="12" md="3" sm="12">
                <v-autocomplete v-model="estado" :items="estados" label="Estado" data-vv-name="Estado" item-text="uf"
                  item-value="uf" prepend-icon="mdi-map" @change="carregarCidades" required />
              </v-col>

              <v-col cols="12" md="5" sm="12">
                <v-autocomplete v-model="cidadeIbge" :items="cidades" :loading="loadingCidades" label="Cidade*"
                  item-text="nome" item-value="ibge" no-data-text="Selecione um estado, para aparecer as cidades"
                  required />
              </v-col>
              <v-col cols="12" md="4" sm="12">
                <v-text-field v-model="bairro" label="Bairro" required />
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field v-model="numero" label="Número" required></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field v-model="complemento" label="Complemento"></v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>


      </v-expansion-panels>

    </v-col>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="btnCancelar" text @click="closeDialog">
        <span class="text-capitalize">Cancelar</span>
      </v-btn>

      <v-btn v-if="!edit" color="primary" type="submit" :loading="loadingSalvar" @click="editarLocal()">
        <span class="text-capitalize">Salvar</span>
      </v-btn>

      <v-btn v-else color="primary" type="submit" :loading="loadingSalvar" @click="editarLocal()">
        <span class="text-capitalize">Salvar</span>
      </v-btn>
    </v-card-actions>
  </v-card>

</template>
<script>
import { ProdutoService, LocaisService, CepService, EstadoService } from "@/services";
import moment from "moment";

export default {
  name: "formularioCadastroLocais",

  props: {
    item: {
      type: [Array, Object],
      default: null
    },

    edit: {
      type: Boolean,
      default: false
    }

  },
  data: () => ({
    mostraambiente: true,
    loadingSalvar: false,
    capaUrl: '',
    capaWidth: '',
    capaHeight: '',
    capaTpMidia: '',
    capaId: null,
    descricao: '',
    data: moment(new Date()).format("Y-MM-DD"),
    endereco: '',
    cep: '',
    logradouro: '',
    estado: '',
    cidadeIbge: '',
    bairro: '',
    numero: '',
    complemento: '',
    itemId: null,
    loadingCidades: false,
    loadingBtn: false,
    cliente: [],
    estabelecimentos: [],
    cidades: [],
    estados: [],
    tiposCadeiras: [],
    tipos: [],
    panel: [0]
  }),

  computed: {
    formatedData() {
      return this.formatDate(this.data);
    },
    checkIfHabilitado() {
      return this.capaUrl !== '' || this.capaUrl !== undefined
        ? true
        : false;
    }
  },
  mounted() {
    this.capaUrl = this.item?.imagemMapa;
    this.capaWidth = this.item?.imagemWidth;
    this.capaHeight = this.item?.imagemHeight;
    this.itemId = this.item?.id;
    this.cliente = this.item?.clienteId;
    this.tiposCadeiras = this.item?.tipoLocalId;
    this.descricao = this.item?.descricao;
    this.mostraambiente = this.item?.habilitaEditor;
    this.cep = this.item?.cep;
    this.estado = this.item?.estado;
    this.bairro = this.item?.bairro;
    this.logradouro = this.item?.logradouro;
    this.numero = this.item?.numero;
    this.complemento = this.item?.complemento;
    // this.data = this.item?.data;
    this.data = moment(this.item?.data).format("Y-MM-DD");
  },

  beforeDestroy() {
    this.capaUrl = null
    this.itemId = null
  },

  created() {
    this.getEstabelecimentos();
    this.getTipos();

  },

  methods: {

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year}`;
    },

    closeDialog() {
      this.$emit('onClose')
    },

    salvarLocal() {
      if (!this.descricao || this.descricao.match(/^ *$/) !== null) {
        return this.$root.showErro("Digite uma descrição")
      }

      if (this.capaUrl == '') {
        return this.$root.showErro("Escolha uma imagem para o evento")
      }

      let payload = {
        descricao: this.descricao,
        imagemMapa: this.capaUrl,
        imagemWidth: this.capaWidth,
        imagemHeight: this.capaHeight,
        clienteId: this.cliente,
        tipoLocalId: this.tiposCadeiras,
        habilitaEditor: this.mostraambiente,
        cep: this.cep,
        estado: this.estado,
        bairro: this.bairro,
        logradouro: this.logradouro,
        numero: this.numero,
        complemento: this.complemento
      };

      console.log(payload);

      LocaisService.cadastroLocal(payload)
        .then((response) => {
          this.$root.showSucesso(response.data);
          this.$emit('success')
        })
        .catch(erro => {
          this.$root.showErro(erro.data);
        })
        .finally(() => {
          this.loadingMidia = false;
        });
    },

    editarLocal() {
      console.log(this.cep, "CEP");
      console.log(this.numero, "numero");

      let payload = {
        descricao: this.descricao,
        imagemWidth: this.capaWidth,
        imagemHeight: this.capaHeight,
        imagemMapa: this.capaUrl,
        id: this.item.id,
        clienteId: this.cliente,
        tipoLocalId: this.tiposCadeiras,
        habilitaEditor: this.mostraambiente,
        cep: this.cep,
        estado: this.estado,
        bairro: this.bairro,
        logradouro: this.logradouro,
        numero: this.numero,
        complemento: this.complemento
      };
      console.log(payload);
      LocaisService.editLocal(payload)
        .then((response) => {
          this.$root.showSucesso(response.data);
          this.$emit('success')
        })
        .catch(erro => {
          this.$root.showErro(erro.data);
        })
        .finally(() => {
          this.loadingMidia = false;
        });
    },

    getEstabelecimentos() {
      ProdutoService.getEstabelecimentos()
        .then((response) => {
          this.estabelecimentos = response.data.map(estabelecimento => ({
            text: estabelecimento.descricao,
            value: estabelecimento.id
          }));
        })
        .catch(erro => {
          this.$root.showErro(erro.data);
        })
        .finally(() => {
          this.loadingMidia = false;
        });
    },

    getTipos() {
      LocaisService.getTipos()
        .then((response) => {
          this.tipos = response.data.map(tipo => ({
            text: tipo.descricao,
            value: tipo.id
          }));
        })
        .catch(erro => {
          this.$root.showErro(erro.data);
        })
        .finally(() => {
          this.loadingMidia = false;
        });
    },

    handleUpload(type, event) {
      if (event && event.target && event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.formData = new FormData();
        this.formData.append("file", this.file);
        this.formData.append("name", this.file.name);

        if (this.file && this.file.type.indexOf('image/') === 0) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
              this.capaWidth = img.width;
              this.capaHeight = img.height;
              console.log(`Largura: ${this.capaWidth}, Altura: ${this.capaHeight}`);
            };
            img.src = e.target.result;
          };
          reader.readAsDataURL(this.file);
        }

        this.capaWidth = this.file.width;
        this.capaHeight = this.file.height;
        ProdutoService.uploadMidia(this.formData)
          .then(response => {
            this.capaUrl = response.data.midiaUrl;
            this.capaTpMidia = response.data.tpMidia;
            this.capaId = response.data.id;
          })
          .catch(erro => {
            this.$root.showErro(erro.data);
          })
          .finally(() => {
            this.loadingMidia = false;
          });
      }
    },

    consultarCEP() {
      if (!this.cep) {
        this.$root.showErro("Informe o CEP para realizar a consulta.");
        return;
      }
      this.loadingBtn = true;
      CepService.consultaCep(this.cep)
        .then(response => {
          this.carregarCidades(response.data.uf);
          this.cep = response.data.cep;
          this.logradouro = response.data.logradouro;
          this.complemento = response.data.complemento;
          this.bairro = response.data.bairro;
          this.localidade = response.data.localidade;
          this.estado = response.data.uf;
          this.cidadeIbge = Number(response.data.ibge);
        })
        .catch(erro => {
          let msg = "Endereço não encontrado";
          if (erro && erro.message) {
            msg = erro.message;
          }
          if (erro.data) {
            msg = erro.data;
          }
          this.$root.showErro(msg);
        })
        .finally(() => {
          this.loadingBtn = false;
        });
    },

    carregarCidades(estado) {
      if (estado) {
        this.estado = estado;
        this.loadingCidades = true;
        EstadoService.getCidadesByUf(estado)
          .then(response => {
            this.cidades = response.data;
            this.loadingCidades = false;
          })
          .catch(() => {
            this.$root.showAlerta(
              "Não foi possível buscar as informações das cidades"
            );
            this.loadingCidades = false;
          });
      }
    },
  }
};
</script>

<style>
.logo-container {
  height: 200px;
  width: 400px;
  border-style: dashed;
}

.w-full {
  width: 100%;
}
</style>