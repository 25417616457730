<template>
    <v-container pa-5 fluid>
        <h2 class="mb-5">Locais</h2>

        <v-card>
        <v-card-text>

            <v-card elevation="1">
            <v-toolbar flat color="white">
                <v-toolbar-title>Lista de Locais</v-toolbar-title>
                <v-divider class="mx-2" inset vertical />
                <v-spacer />
                <v-btn color="primary" class="mb-2" outlined @click="openLocal()">
                Novo Local
                </v-btn>
            </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="eventos"
                :loading="loading"
                class="elevation-1"
                loading-text="Carregando..."
            >
                <template slot="headerCell" slot-scope="{ header }">
                <span
                    class="subheading font-weight-light text--darken-3"
                    v-text="header.text"
                />
                </template>
                <template v-slot:body="{ items }" ma-5>
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                    <td >{{ item.descricao }}</td>
                    <td>{{ item.tipoLocalDescricao }}</td>
                    <td  class="w-12"  >{{ item.data | filtraDH }}</td>
                    <!-- <td class="w-10">
                        <v-img contain :src="item.midia" max-width="150px" max-height="150px"/>
                    </td> -->
                    <td class="text-right  w-12">
                        <v-icon
                        class="mr-2"
                        title="Ambiente " 
                        :disabled="!item.habilitaEditor"
                        @click="openModelagem(item)"
                        >mdi-table-cog</v-icon
                        >
                        <!-- <v-icon
                        class="mr-2"
                        title="Ambiente Atualizacao" 
                        @click="openModelagemUpdate(item)"
                        >mdi-application-edit-outline</v-icon
                        > -->
                        <v-icon
                        class="mr-2"
                        title="Editar"
                        @click="handleEdit(item)"
                        >mdi-pencil</v-icon
                        >
                        <v-icon
                        class="mr-2"
                        title="Deletar"
                        color="primary"
                        @click="deleteItem(item)"
                        >mdi-delete</v-icon
                        >
                    </td>
                    </tr>
                </tbody>
                </template>
            </v-data-table>
            </v-card>
        </v-card-text>
        </v-card>
        <v-dialog
            v-model="dialogCadastroLocal"
            hide-overlay 
            persistent 
            width="700px"
            class="no-overflow"
        >
        <formulario-cadastro-local
            :edit="edit"
            :item="eventoObj"
            :key="key"
            @onClose="dialogCadastroLocal = false"  
            @success="getLocais()"
        />
       
        </v-dialog>
        <v-dialog
            v-model="dialogModelagemLocal"
            hide-overlay 
            persistent 
            fullscreen
            class="no-overflow"
        >
        <modelagem
            :edit="edit"
            :item="eventoObj"
            :key="key"
            @onClose="dialogModelagemLocal = false"  
            @success="getLocais()"
        />
        </v-dialog>

        <v-dialog
            v-model="dialogModelagemLocalUpdate"
            hide-overlay 
            persistent 
            fullscreen
            class="no-overflow"
        >
        <modelagem-update
            :edit="edit"
            :item="eventoObj"
            :key="key"
            @onClose="dialogModelagemLocalUpdate = false"  
            @success="getLocais()"
        />
        </v-dialog>

        <v-dialog
        v-model="loader"
        hide-overlay
        persistent
        width="300"
        >
        <v-card
            color="primary"
            dark
        >
            <v-card-text>
            Aguarde
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
            </v-card-text>
        </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { LocaisService } from '@/services';
import { Role } from "@/constants";
import  formularioCadastroLocal from './formularioCadastroLocais.vue'
import  modelagem from './modelagem.vue'
import  modelagemUpdate from './modelagemUpdate.vue'
import moment from "moment";

export default {
    components: {
        formularioCadastroLocal,
        modelagem,
        modelagemUpdate
    },

    data() {
        return {
            loader: false,
            loading: false,
            dialogCadastroLocal: false,
            dialogModelagemLocal: false,
            dialogModelagemLocalUpdate: false,
            eventoObj: {},
            eventoId: 0,
            eventos: [],
            edit: false,
            headers: [
                { text: "Descrição", value: "descricao" },
                { text: "Tipo", value: "tipoLocalId" },
                { text: "Data Cadastro", value: "data" },
                { text: "Açoes", value: "actions" },
            ],
            key: 0,
        }
    },
    filters: {
        filtraDH(val) {
            if (val) {
                return moment(val).format("DD/MM/YYYY");
            } else {
                return "Inválido";
            }
        },
    },
    created(){
        this.getLocais()
        if(this.$store.getters["user/getUser"].roleId !== Role.ROOT && this.$store.getters["user/getUser"].roleId !== Role.ADMIN && this.$store.getters["user/getUser"].roleId !== Role.USER){
            this.$router.push('/check-in')
        }
    },

    methods: {

        openLocal(){
            this.eventoObj = []
            this.dialogCadastroLocal = true
            this.key += 1
        },
        
        openModelagem(item){
            this.loader = true
            LocaisService.getLocalDetalhe(item.id)
             .then(response => {
                if(response){
                    this.edit = true
                    this.key += 1
                    this.dialogModelagemLocal = true
                    this.eventoObj = response.data
                    this
                }
            })
            .finally(() => {
            this.loader = false;
            this.loading = false;
            });
        },

        openModelagemUpdate(item){
            this.loader = true
            console.log(item)
            LocaisService.getLocalDetalhe(item.id)
             .then(response => {
                if(response){
                    this.edit = true
                    this.key += 1
                    this.dialogModelagemLocalUpdate = true
                    this.eventoObj = response.data
                    this
                }
            })
            .finally(() => {
            this.loader = false;
            this.loading = false;
            });
        },

        getLocais() {
            this.dialogCadastroLocal = false 
            this.dialogModelagemLocal = false 
            this.loading = true
            LocaisService.pagination()
            .then(response => {
                this.eventos = response.data
            })
            .finally(() => {
            this.loadingPesquisar = false;
            this.loading = false;
            });
        },

        handleEdit(item){
            this.loader = true
            console.log(item)
            LocaisService.getLocalDetalhe(item.id)
             .then(response => {
                if(response){
                    this.edit = true
                    this.key += 1
                    this.dialogCadastroLocal = true
                    this.eventoObj = response.data
                    this
                }
            })
            .finally(() => {
            this.loader = false;
            this.loading = false;
            });
        },

        deleteItem(item){
            this.loader = true
            LocaisService.deleteLocal(item.id)
             .then(response => {
                if(response){
                    this.getLocais()
                }
            })
            .finally(() => {
            this.loader = false;
            this.loading = false;
            });
        }
    }
}
</script>

<style>
.w-10{
    width: 10%;
}
.w-12{
    width: 12%;
}
</style>