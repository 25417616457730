<template>
  <v-card class="w-full set-screen">
    <v-toolbar elevation="1" flat>
      <v-col cols="3">
        <v-toolbar-title>
          <span class="headline"> Design de Ambientes</span>
        </v-toolbar-title>
      </v-col>
      <v-col cols="9" class="d-flex align-center justify-end pr-10">
        <v-btn color="primary" class="mr-2" outlined @click="openCategoriaLocal()">
          Editar Categorias
        </v-btn>

        <v-icon large @click="closeDialog">mdi-close</v-icon>
      </v-col>
    </v-toolbar>

    <div class="d-flex flex-wrap">
      <div class="topbar pa-4 d-flex justify-space-between align-center">
        <span class="subtitle topbar-title ">
          {{ descricaoModelo }}
        </span>
        <div class="topbar-actions mr-10 d-flex align-center">
          <!-- <v-text-field v-model="filtro" placeholder="Pesquisar" />
          <v-btn icon>
            <v-icon>mdi-tag</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon>mdi-menu</v-icon>
          </v-btn> -->
        </div>
      </div>
      <div class="sidebar d-flex justify-center flex-wrap">
        <div>
          <!-- <v-btn icon class="w-100 mt-2" @click="isAddActive = false">
            <v-icon :color="!isAddActive && 'primary'">mdi-navigation</v-icon>
          </v-btn> -->
          <v-btn icon class="w-100 mt-2" @click="novaCadeira">
            <v-icon :color="isAddActive && 'primary'">mdi-plus-box</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="room-container">
        <div class="d-flex flex-wrap w-100">
          <div v-for="(cadeira, index) in cadeiras" :key="index">
            <img :src="cadeira.url" alt="" class="lista" :id="cadeira.id" :style="{
              top: cadeira.coordy + 'px',
              left: cadeira.coordx + 'px',
              width: '15px',
            }" @dblclick="mouseClick(cadeira)" />
          </div>

          <img :src="imagemMapa" alt="" class="ui-widget-header image-set" ref="myElement" />
          <div class="w-100 d-flex justify-end">
            <v-btn class="mt-4" color="primary" @click="salvar"> Salvar </v-btn>
          </div>
        </div>
        <template v-if="dialogDelete">
          <v-row justify="center">
            <v-dialog v-model="dialogDelete" persistent max-width="300px">
              <v-card>
                <v-form ref="formDelete" v-model="valid" lazy-validation @submit.prevent="deleteCategoria">
                  <v-card-title>
                    <span class="headline">Edição da Cadeira</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="descricao" label="Descrição:" persistent-hint>
                          </v-text-field>
                        </v-col>

                        <!-- <v-col cols="12">
                          <v-text-field
                            v-model="codigo"
                            label="Codigo:"
                            disabled
                            persistent-hint
                          >
                          </v-text-field>
                        </v-col> -->

                        <v-col cols="12">
                          <v-text-field v-model="sigla" label="Sigla:" persistent-hint>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-select v-model="categoriaSelect" label="Categorias*:" :items="categorias" />
                        </v-col>

                        <v-col cols="12">
                          <v-select v-model="tipoCadeiraSelect" label="Tipo de Cadeira*:" :items="tipoCadeira" />
                        </v-col>

                        <v-col cols="12">
                          <v-select v-model="situacaoSelect" label="Situacao*:" :items="situacoes" />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btnCancelar" text @click="() => (dialogDelete = false)">
                      Cancelar
                    </v-btn>
                    <!-- <v-btn :disabled="!valid" color="primary" type="submit" :loading="loadingSalvar">
                                    <span style="color: white;">Excluir</span>
                                </v-btn> -->
                    <v-btn color="primary" type="submit" :loading="loadingSalvar">
                      <span style="color: white;">Excluir</span>
                    </v-btn>

                    <v-btn color="primary" @click="salvarCadeira()">
                      <span style="color: white;">Salvar</span>
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-row>
        </template>
        <template>
          <v-dialog v-model="dialogCategoria" hide-overlay persistent width="700px" class="no-overflow">
            <formulario-categorias :edit="edit" :item="categoriaObj" :id="itemId" @onClose="closeCategoria()"
              @success="getLocais()" />
          </v-dialog>
        </template>
      </div>
    </div>
  </v-card>
</template>

<script>
import { LocaisService, LocaisCategoriaService } from "@/services";

import formularioCategorias from "./categorias.vue";
import * as jq from "jquery";

export default {
  components: {
    formularioCategorias,
  },

  name: "formularioModelagemLocais",

  props: {
    item: {
      type: [Array, Object],
      default: null,
    },

    edit: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isAddActive: false,
    filtro: "",
    dialogCategoria: false,
    categoriaObj: {},
    dialogDelete: false,
    servicosLength: 0,
    imagemMapa: "",
    sigla: "",
    tipoCadeiraSelect: [],
    tipoCadeira: [],
    itemId: "",
    cliente: "",
    tiposCadeiras: "",
    descricao: "",
    descricaoModelo: "",
    cadeiraAtual: "",
    coordx: 0,
    coordy: 0,
    tipoAssentoId: 0,
    codigo: "",
    categoriaSelect: [],
    categorias: [],
    situacaoSelect: [],
    situacoes: [],
    cadeiras: [],
    cadeirasLength: 0,
    id: 0,
  }),

  mounted() {
    this.imagemMapa = this.item?.imagemMapa;
    this.itemId = this.item?.id;
    this.cliente = this.item?.clienteId;
    this.tiposCadeiras = this.item?.tipoLocalId;
    this.descricaoModelo = this.item?.descricao;
    this.categoriaObj = this.item;
    // this.data = this.item?.data;
    // this.data = moment(this.item?.data).format("Y-MM-DD");

    // MONTAGEM DAS CADEIRAS
    jq.extend(require("jquery-ui-dist/jquery-ui"));
    window.jq = jq;

    this.getAllCadeira();

    this.loadCategorias();
    this.loadSituacoes();
    this.dragginElements();

  },
  watch: {
    cadeiras: function (newVal) {
      // watch it
      let changed = [];
      changed.push(...newVal);
      let newItens = changed.splice(this.cadeirasLength, changed.length);
      this.dragginNewElements(newItens);
      this.servicosLength = newVal.length;
    },
  },

  created() {
    this.getTipos();
  },

  methods: {
    getAllCadeira() {
      LocaisService.getLocalItem(this.itemId)
        .then((response) => {
          console.log(response, "response");
          this.cadeiras = response.data.map((cadeira) => ({
            descricao: cadeira.descricao,
            coordx: cadeira.coordX,
            coordy: cadeira.coordY,
            tipoAssentoId: cadeira.tipoAssentoId,
            situacaoItemId: cadeira.situacaoItemId,
            localCategoriaId: cadeira.localCategoriaId,
            sigla: cadeira.sigla,
            localId: cadeira.localId,
            id: cadeira.id,
            url:
              cadeira.situacaoItemId == 1
                ? "../img/canvas/livre_editado.png"
                : cadeira.situacaoItemId == 2
                  ? "../img/canvas/livre.png"
                  : "../img/canvas/manutencao.png",
          }));
        })
        .finally(() => {
          this.cadeirasLengthLength = this.cadeiras.length;
          this.loader = false;
          this.loading = false;
          console.log(this.cadeiras, "cadeiras finish ");
          this.dragginElements();
        });
    },

    updateImagemCadeira(situacaoId) {
      if (situacaoId == 1) {
        return "../img/canvas/livre_editado.png";
      }
      if (situacaoId == 2) {
        return "../img/canvas/livre.png";
      }
      if (situacaoId == 3) {
        return "../img/canvas/manutencao.png";
      }
    },

    salvarCadeira() {
      this.cadeiraAtual.url = this.updateImagemCadeira(this.situacaoSelect);
      this.cadeiraAtual.descricao = this.descricao;
      this.cadeiraAtual.coordx = this.coordx;
      this.cadeiraAtual.coordy = this.coordy;
      this.cadeiraAtual.tipoAssentoId = this.tipoCadeiraSelect;
      this.cadeiraAtual.situacaoItemId = this.situacaoSelect;
      this.cadeiraAtual.localCategoriaId = this.categoriaSelect;
      this.cadeiraAtual.sigla = this.sigla;
      this.cadeiraAtual.localId = this.itemId;

      if (this.tipoCadeiraSelect == "") {
        return this.$root.showErro("Escolha uma tipo cadeira");
      }

      console.log(this.cadeiraAtual, "console cadeira Antes");

      let payload = {
        descricao: this.descricao,
        coordx: this.coordx,
        coordy: this.coordy,
        tipoAssentoId: this.tipoCadeiraSelect,
        situacaoItemId: this.situacaoSelect,
        localCategoriaId: this.categoriaSelect,
        sigla: this.sigla,
        localId: this.itemId,
        id: this.cadeiraAtual.id,
      };

      this.cadeiraAtual = payload;
      console.log(this.cadeiraAtual, "console cadeira");

      LocaisService.cadastroItem(payload)
        .then((response) => {
          this.$root.showSucesso(response.data);
          // this.$emit('success');
          this.dialogDelete = false;
          this.dragginElements();
        })
        .catch((erro) => {
          this.$root.showErro(erro.data);
        })
        .finally(() => {
          this.loadingMidia = false;
        });
    },

    openCategoriaLocal() {
      console.log(this.itemId, "item open cate");
      LocaisCategoriaService.getLocalCategoriaDetalhe(this.itemId)
        .then((response) => {
          if (response) {
            console.log(response.data, "getlocalStart");
            this.edit = true;
            this.categoriaObj = response.data;
            this.dialogCategoria = true;
            this;
          }
        })
        .finally(() => {
          this.loader = false;
          this.loading = false;
        });
    },

    closeCategoria() {
      this.dialogCategoria = false;
      this.loadCategorias();
    },

    getTipos() {
      LocaisService.getTiposAssentos()
        .then((response) => {
          this.tipoCadeira = response.data.map((tipo) => ({
            text: tipo.descricao,
            value: tipo.id,
          }));
        })
        .catch((erro) => {
          this.$root.showErro(erro.data);
        })
        .finally(() => {
          this.loadingMidia = false;
        });
    },

    dragginNewElements(elements) {
      if (!Array.isArray(elements)) {
        console.log(elements, "teste");
        return;
      }
      elements.forEach((element) => {
        setTimeout(() => {
          console.log(jq("#" + element.id).attr("id"));
          jq("#" + element.id).draggable();
          jq("#" + element.id).on("mouseup", (e) => {

            if (element.descricao === undefined || element.descricao === '') {
              // this.$root.showInfo("Cadeira não editada!");
              element.coordx = e.target.offsetLeft,
                element.coordy = e.target.offsetTop,
                this.cadeiraAtual = element
            } else {

              // this.$root.showInfo(element.descricao),
              element.coordx = e.target.offsetLeft,
                element.coordy = e.target.offsetTop,
                this.cadeiraAtual = element,
                console.log(this.cadeiraAtual, "console");
            }

          });
        }, 0);
      });
    },
    dragginElements() {
      this.dragginNewElements(this.cadeiras);
    },

    closeDialog() {
      this.$emit("onClose");
    },

    novaCadeira() {
      this.isAddActive = true;
      this.coordx = 790;
      this.coordy = 10;

      var elementoid = this.cadeiras.length + 1;
      // var elementoid = this.servicos.length + 1;
      this.cadeiras.push({
        descricao: "",
        coordx: this.coordx,
        coordy: this.coordy,
        tipoAssentoId: null,
        situacaoItemId: null,
        localCategoriaId: null,
        sigla: "",
        localId: this.itemId,
        id: elementoid,
        url: "../img/canvas/livre.png",
      });

      jq("#" + elementoid).draggable();
      jq("#" + elementoid).on("mouseup", (e) =>
        console.log(
          this.$root.showErro(
            this.$root.showInfo(elementoid.descricao),
            (this.coordx = e.target.offsetLeft),
            (this.coordy = e.target.offsetTop),
            (this.cadeiraAtual = elementoid)
          )
        )
      );
    },

    mouseClick(item) {
      console.log(item, "item click");
      console.log(this.cadeiraAtual, "cadeira click");
      this.cadeiraAtual = item;
      this.dialogDelete = true;

      this.descricao = item.descricao;
      this.coordx = item.coordx;
      this.coordy = item.coordy;
      this.tipoCadeiraSelect = item.tipoAssentoId;
      this.situacaoSelect = item.situacaoItemId;
      this.categoriaSelect = item.localCategoriaId;
      this.sigla = item.sigla;
      // this.itemId = item.localId;
      this.id = item.id;

      console.log(item);
      // console.log(this.cadeiraAtual, "cadeira atual");

      //this.servicos.splice(1, 1);
      // console.log(this.servicos);
      // // servico.url = '../img/canvas/livre_editado.png';
      // // this.servicos.push({ url: '../img/canvas/livre_editado.png', id: '2', x: '232', y: '289', tipo: 'Padrão', situacao: 'cadastrando' });
      // console.log(this.servicos);
      // console.log(this.servicos.currentTarget);
      // console.log("close ");
    },

    loadCategorias() {
      console.log(this.itemId, "item id load");
      LocaisCategoriaService.getLocalCategoriaDetalheNotNull(this.itemId)
        .then((response) => {
          console.log(response, "loacCategorias");

          if (response) {
            this.categorias = response.data.map((categ) => ({
              text: categ.descricao,
              value: categ.id,
            }));
            this.categoriaObj = response.data;
          }
        })
        .finally(() => {
          this.loader = false;
          this.loading = false;
        });
    },

    loadSituacoes() {
      LocaisCategoriaService.getLocalSituacoes()
        .then((response) => {
          console.log(response, "response");

          if (response) {
            this.situacoes = response.data.map((situ) => ({
              text: situ.descricao,
              value: situ.id,
            }));
          }
        })
        .finally(() => {
          this.loader = false;
          this.loading = false;
        });
    },

    openDialogDelete() {
      this.dialogDelete = true;
    },

    excluirCadeira() { },

    salvar() {
      let payload = this.cadeiras.map((cadeira) => ({
        descricao: cadeira.descricao,
        coordX: cadeira.coordx,
        coordY: cadeira.coordy,
        tipoAssentoId: cadeira.tipoAssentoId,
        situacaoItemId: cadeira.situacaoItemId,
        localCategoriaId: cadeira.localCategoriaId,
        sigla: cadeira.sigla,
        localId: cadeira.localId,
        id: cadeira.id,
      }));


      let payloadLocal = {
        id: this.itemId,
        imagemWidth: this.$refs.myElement.clientWidth,
        imagemHeight: this.$refs.myElement.clientHeight,
      };

      LocaisService.saveImagem(payloadLocal)
        .then((response) => {
          this.$root.showSucesso(response.data);
        })
        .catch((erro) => {
          this.$root.showErro(erro.data);
        })
        .finally(() => {});

      LocaisService.saveAllCadeiras(payload)
        .then((response) => {
          this.$root.showSucesso(response.data);
          this.$emit("success");
          this.dragginElements();
        })
        .catch((erro) => {
          this.$root.showErro(erro.data);
        })
        .finally(() => {
          this.loadingMidia = false;
        });
    },
  },
};
</script>

<style scoped>
.room-container {
  position: relative;
}

.chair {
  position: absolute;
  width: 10 px;
  left: 631px;
  top: 10px;
  cursor: move;
}

.lista {
  position: absolute;
  width: 10 px;
  background: "@/assets/canvas/livre.png";
}

.position-absolute {
  top: 0px;
  left: 0px;
  position: absolute;
  width: 1024px;
  height: 768px;
}

.image-set {
  min-width: 100%;
}

.w-full {
  width: 100%;
  height: 500px;
}

.subtitle {
  font-size: 1.2rem !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
}

.w-100 {
  width: 100%;
}

.sidebar {
  min-height: 100vh;
  width: 60px;
  background-color: var(--v-ice-base);
}

.set-screen {
  height: 100vh;
  overflow: hidden;
}

.w-30 {
  width: 30%;
}

.topbar {
  width: 100%;
  height: 60px;
  background-color: var(--v-ice-base);
}
</style>
